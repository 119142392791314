import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from '@iconify/react';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import type { FC } from 'react';

import { useProfile } from '@/hooks/useProfile';
import { useUserCurrentSubscription } from '@/hooks/useUserCurrentSubscription';
import { queries } from '@/queries';

import SubscribeButton from '../SubscribeButton';
import Text from '../Text';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';

type Props = {
  dark?: boolean;
  className?: string;
};
const CvUsage: FC<Props> = ({ className, dark }) => {
  const { data: profileData } = useProfile();
  const { data: userCurrentSubscriptionData } = useUserCurrentSubscription();

  const { data } = useQuery({
    ...queries.cvGenerator.userCvUsage,
    enabled: !!profileData,
    meta: { silent: true },
  });

  const cvLeft =
    typeof data?.limit === 'number' && typeof data?.counter === 'number'
      ? data.limit - data.counter
      : null;
  const isUnlimitedSubscription = data?.limit === null;

  const detailedUserCvUsageData = [
    { value: data?.counter, label: 'Resumes used in this billing cycle' },
    { value: data?.limit, label: 'Resume limit for this billing cycle' },
    {
      value: cvLeft,
      label: 'Resume left for this billing cycle',
    },
    {
      value: data?.currentMonthlyUsageStart,
      label: 'Date of first resume anonymization in this billing cycle',
    },
    {
      value: data?.currentMonthlyUsageLastUse,
      label: 'Date of last resume anonymization',
    },
    {
      value: data?.subscriptionCancelAt
        ? data?.subscriptionCancelAt
        : data?.subscriptionEnd,
      label: data?.subscriptionCancelAt
        ? 'Subscription canceled: Your current package will end on this date, and no further payments will be taken.'
        : 'Next payment date and resume limit refresh',
    },
  ];

  if (!profileData) return null;

  const hasGeneratorAccess =
    isUnlimitedSubscription ||
    userCurrentSubscriptionData ||
    profileData.accountType === 'RECRUITER';

  return (
    <div className={classNames('flex-col hidden md:block gap-0', className)}>
      <div className="flex w-full flex-row items-center justify-end gap-3">
        {hasGeneratorAccess && (
          <>
            <Icon icon="game-icons:two-coins" className="text-primary-500" />
            <Text
              variant="body-caption"
              className={classNames('flex flex-row', {
                'text-neutral-1000': dark,
              })}
            >
              {isUnlimitedSubscription && <span>Unlimited</span>}
              {!isUnlimitedSubscription && (
                <>
                  <span>{cvLeft}</span>
                  {`/${data?.limit}`}
                </>
              )}
            </Text>
            <Popover>
              <PopoverTrigger>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className={classNames('cursor-pointer', {
                    'text-neutral-1000': dark,
                  })}
                />
              </PopoverTrigger>
              <PopoverContent className="w-full max-w-sm border-none bg-neutral-1000 p-3">
                <div className="flex flex-col gap-2">
                  {detailedUserCvUsageData.map(
                    ({ value, label }) =>
                      (typeof value === 'string' ||
                        typeof value === 'number') && (
                        <Text variant="body-caption" key={`${label} ${value}`}>
                          {label}:&nbsp;
                          {value}
                        </Text>
                      )
                  )}
                </div>
              </PopoverContent>
            </Popover>
          </>
        )}
        <SubscribeButton
          size="sm"
          isUnlimitedSubscription={isUnlimitedSubscription}
        />
      </div>
    </div>
  );
};

export default CvUsage;
