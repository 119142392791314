import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { DialogContentProps, DialogProps } from '@radix-ui/react-dialog';
import type { FC } from 'react';

import Loader from '@/assets/Loader.svg';

import Text from '../Text';
import { Dialog, DialogContent } from '../ui/dialog';

type Props = {
  loadingState: {
    summaryCompleted: boolean;
    filtersCompleted: boolean;
    keywordsCompleted: boolean;
    requirementsCompleted: boolean;
    characteristicsCompleted: boolean;
  };
  dialogProps?: DialogProps;
  dialogContentProps?: DialogContentProps;
};

const AdvancedSearchLoading: FC<Props> = ({
  loadingState,
  dialogContentProps,
  dialogProps,
}) => {
  const {
    characteristicsCompleted,
    filtersCompleted,
    keywordsCompleted,
    requirementsCompleted,
    summaryCompleted,
  } = loadingState;
  const steps = [
    {
      label: 'Summarizing your search',
      state: summaryCompleted,
    },
    { label: 'Extracting Keywords', state: keywordsCompleted },
    {
      label: 'Extracting Filters',
      state: filtersCompleted,
    },
    { label: 'Generating Characteristics', state: characteristicsCompleted },
    {
      label: 'Generating Requiremenets',
      state: requirementsCompleted,
    },
  ];

  const readySteps = Object.values(steps).filter((step) => step.state).length;
  return (
    <Dialog {...dialogProps}>
      <DialogContent className=" !bg-transparent p-0" {...dialogContentProps}>
        <div className="rounded-2xl bg-gradient-to-r from-[#00193F] to-[#885AFD]">
          <div className="m-[2px] flex flex-col items-center justify-center gap-2 rounded-2xl bg-gradient-to-r from-[#000000] to-[#001435] p-6">
            <div className="mb-4 h-min w-min animate-spin">
              <Loader />
            </div>
            <Text variant="h4" className="font-semibold">
              Please, wait
            </Text>
            {steps.slice(0, readySteps + 1).map(({ label, state }) => (
              <div key={label} className="flex items-center gap-2">
                <Text>{label}</Text>
                {state && <FontAwesomeIcon icon={faCheckCircle} />}
              </div>
            ))}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AdvancedSearchLoading;
