import { useQuery } from '@tanstack/react-query';

import { queries } from '@/queries';

import { useProfile } from './useProfile';

export const useUserCurrentSubscription = () => {
  const { data } = useProfile();
  return useQuery({
    ...queries.payments.getUserCurrentSubscriptionData,
    enabled: !!data,
  });
};
