/* eslint-disable react/no-array-index-key */
import type { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { Icon } from '@iconify/react';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import type { ArgumentArray } from 'classnames';
import classNames from 'classnames';
import type { FC, PropsWithChildren, ReactElement, ReactNode } from 'react';
import React, { useCallback, useEffect } from 'react';
import type { DropzoneOptions } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-hot-toast';

import Button, { buttonStyles } from '@/components/Button';
import ErrorAccordion from '@/components/ErrorAccordion';
import LoadingPlaceholder from '@/components/LoadingPlaceholder';
import Text from '@/components/Text';
import { useFileDrag } from '@/hooks/useFileDrag';

import type { ErrorDetailsProps } from '../ArrayDataNestedForm';
import Label from './Label';

type FileInputBackgroundProps = VariantProps<
  ReturnType<typeof backgroundStyles>
>;
const backgroundStyles = (isDragging: boolean) =>
  cva('flex cursor-pointer flex-col items-center justify-center', {
    variants: {
      variant: {
        normal:
          'mt-4 min-h-[9rem] gap-1 rounded-lg border-2 border-transparent bg-secondary-500/20 p-4',
        transparent: classNames(
          'py-6 rounded-[8px] bg-neutral-100 bg-dashed-border-add-cv',
          { 'bg-primary-100': isDragging }
        ),
        unstyled: '',
        lightBlue: classNames(
          'py-6 flex-col rounded-[8px] bg-neutral-100 bg-dashed-border-light-blue',
          { 'bg-primary-100': isDragging }
        ),
      },
    },
    defaultVariants: {
      variant: 'normal',
    },
  });

type FileInputUploadFilesSpanProps = VariantProps<
  ReturnType<typeof uploadFilesSpanStyles>
>;
const uploadFilesSpanStyles = (error?: string) =>
  cva('', {
    variants: {
      variant: {
        normal: classNames('text-primary-500 font-semibold', {
          '!text-error': error,
        }),
        transparent: classNames('text-primary-500 font-semibold', {
          '!text-error': error,
        }),
        unstyled: '',
        lightBlue: classNames('text-jb-primary-400', {
          '!text-error': error,
        }),
      },
    },
    defaultVariants: {
      variant: 'normal',
    },
  });

type FileInputIconProps = VariantProps<typeof iconStyles>;
const iconStyles = cva('', {
  variants: {
    variant: {
      normal: 'mb-4 text-[40px] text-primary-500',
      transparent: 'mb-4 text-[40px] text-primary-500',
      unstyled: '',
      lightBlue: 'text-[40px] text-jb-primary-400',
    },
  },
  defaultVariants: {
    variant: 'normal',
  },
});

type FileInputTextProps = VariantProps<typeof textStyles>;
const textStyles = cva('text-center', {
  variants: {
    variant: {
      normal: 'text-neutral-300',
      transparent: 'text-neutral-800',
      unstyled: '',
      lightBlue: 'text-neutral-800',
    },
  },
  defaultVariants: {
    variant: 'normal',
  },
});

export interface FileInputVariants
  extends FileInputBackgroundProps,
    FileInputUploadFilesSpanProps,
    FileInputIconProps,
    FileInputTextProps {}

type IsMultiple<T extends boolean> = T extends true
  ? File[] | Blob[]
  : File | Blob;

type Value<T, Clearable extends boolean> = Clearable extends true
  ? T | undefined
  : T;

type ButtonVariants = VariantProps<typeof buttonStyles>;
type ConditionalVariantProps<IsButton extends boolean> = IsButton extends true
  ? ButtonVariants & { prefixIcon?: string | IconDefinition }
  : FileInputVariants & { prefixIcon?: undefined };

type Props<
  T extends boolean,
  Clearable extends boolean,
  IsButton extends boolean
> = {
  label?: string;
  description?: string;
  required?: boolean;
  customActionButton?: ReactNode;
  name?: string;
  isLoading?: boolean;
  value?: Value<IsMultiple<T>, Clearable>;
  multiple?: T;
  onChange: (value: Value<IsMultiple<T>, Clearable>) => void;
  clearable?: Clearable;
  defaultFileName?: string;
  labelClassName?: string;
  buttonLabel?: string;
  isButtonStyled?: IsButton;
  className?: (
    isDragging: boolean,
    variant: ConditionalVariantProps<IsButton>
  ) => ArgumentArray;
  showFileList?: boolean;
  containerClassName?: string;
  alwaysShowInput?: boolean;
} & Omit<DropzoneOptions, 'multiple'> &
  ConditionalVariantProps<IsButton> &
  ErrorDetailsProps<string>;

export const getExtensionsString = (extensions?: {
  [key: string]: string[];
}) => {
  return [...Object.values(extensions ?? {})]
    .map((value) => value.map((type) => type.toUpperCase()).join(', '))
    .join(', ');
};

const FileInput = <
  T extends boolean = false,
  Clearable extends boolean = false,
  IsButton extends boolean = false
>({
  onChange,
  value,
  label,
  description,
  required = false,
  error,
  customActionButton,
  name,
  isLoading = false,
  multiple,
  variant,
  clearable,
  isButtonStyled,
  prefixIcon,
  buttonLabel = 'Upload files',
  canHaveErrorMessage = true,
  defaultFileName = 'resume.pdf',
  labelClassName,
  className,
  maxSize = 10 * 1024 * 1024,
  children,
  showFileList = true,
  containerClassName,
  alwaysShowInput = false,
  ...props
}: PropsWithChildren<Props<T, Clearable, IsButton>>) => {
  const { isDragging } = useFileDrag();
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length <= 0) return;
      if (!multiple)
        return onChange(acceptedFiles[0] as (File | Blob) & (File[] | Blob[]));
      onChange([...acceptedFiles, ...(Array.isArray(value) ? value : [])] as (
        | File
        | Blob
      ) &
        (File[] | Blob[]));
    },
    [onChange, value]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    ...props,
    maxSize,
    multiple,
    onDrop,
    onDropRejected: (files) => {
      toast.error(
        `Can't upload file: ${files
          .map(
            (file) =>
              `${file.file.name}: ${
                file.errors[0].code === 'file-too-large'
                  ? `File is larger than ${Math.ceil(maxSize / 1024 / 1024)} MB`
                  : file.errors[0].message
              }`
          )
          .join(',')}`
      );
    },
  });

  const getVerifiedVariantProps = (): ConditionalVariantProps<IsButton> => {
    if (isButtonStyled) {
      return {
        variant: variant as ButtonVariants['variant'],
      } as ConditionalVariantProps<IsButton>;
    }
    return {
      variant: variant as FileInputVariants['variant'],
    } as ConditionalVariantProps<IsButton>;
  };

  const getVerifiedVariantStyles = () => {
    if (isButtonStyled) {
      return buttonStyles({ variant: variant as ButtonVariants['variant'] });
    }
    return backgroundStyles(isDragging)({
      variant: variant as FileInputVariants['variant'],
    });
  };

  const onRemoveSingleFile = (file: File | Blob) => {
    if (Array.isArray(value))
      return onChange(
        value.filter((val) => val.name !== file.name) as Value<
          IsMultiple<T>,
          Clearable
        >
      );
    return onChange(undefined as Value<IsMultiple<T>, Clearable>);
  };

  const acceptedFileTypeString = getExtensionsString(props.accept);

  const getTitle = () => {
    if (children)
      return React.cloneElement(children as ReactElement, {
        ...getRootProps(),
      });
    if (isButtonStyled) {
      if (isDragging)
        return (
          <div
            {...getRootProps()}
            className="fixed left-0 top-0 z-[200] flex h-screen w-screen flex-col items-center justify-center gap-10 bg-gradient-to-r from-black to-secondary-900"
          >
            <Text
              variant="h1"
              className="text-center font-semibold text-neutral-200"
            >
              Drop it like it&apos;s hot!
            </Text>
            <Text className="max-w-[17.5rem] text-center text-neutral-200">
              You`re almost done!
              <br /> Drop your files anywhere here 👇
            </Text>
          </div>
        );
      return (
        <Button
          size="long"
          prefixIcon={prefixIcon}
          variant={variant as ButtonVariants['variant']}
        >
          {buttonLabel}
        </Button>
      );
    }

    if (isDragging) {
      return (
        <Text className="font-semibold text-primary-500" variant="h5">
          Drop it like it&apos;s hot
        </Text>
      );
    }
    return (
      <div className="flex flex-col items-center">
        <Icon
          icon="solar:upload-outline"
          className={iconStyles({
            variant: variant as FileInputVariants['variant'],
          })}
        />
        <Text
          variant="body"
          className={textStyles({
            variant: variant as FileInputVariants['variant'],
          })}
        >
          <span
            className={uploadFilesSpanStyles(error)({
              variant: variant as FileInputVariants['variant'],
            })}
          >
            Upload {multiple ? 'files' : 'a file'}
          </span>
          {' or drag and drop here'}
        </Text>
        <Text
          variant="body"
          className={textStyles({
            variant: variant as FileInputVariants['variant'],
          })}
        >
          We accept {acceptedFileTypeString} files.
        </Text>
      </div>
    );
  };
  useEffect(() => {
    if (isDragActive) document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isDragActive]);
  return (
    <div className={containerClassName}>
      <div className="flex flex-col gap-4 ">
        {label && (
          <Label className={labelClassName} required={required}>
            {label}
          </Label>
        )}
        {description && (
          <Text as="span" className="text-gray-300">
            {description}
          </Text>
        )}

        <div
          {...(!isDragging || !isButtonStyled ? getRootProps() : {})}
          className={classNames(
            [getVerifiedVariantStyles()],
            {
              hidden: !multiple && !!value && !alwaysShowInput,
              '!border-error !bg-error-20%': error,
              'w-fit !p-0': isButtonStyled,
            },
            ...(className?.(isDragging, getVerifiedVariantProps()) ?? [])
          )}
          tabIndex={isButtonStyled ? -1 : undefined}
        >
          {isLoading ? (
            <LoadingPlaceholder />
          ) : (
            <>
              <input
                name={name}
                {...getInputProps()}
                tabIndex={isButtonStyled ? -1 : undefined}
              />
              <div data-cy={`dropzone-${name}`}>{getTitle()}</div>
              {customActionButton && (
                <>
                  <Text className="text-gray-300" variant="body-small">
                    or
                  </Text>
                  {customActionButton}
                </>
              )}
              {clearable && value && (
                <Button
                  variant="secondary"
                  size="md"
                  prefixIcon={faXmark}
                  onClick={(e) => {
                    e.stopPropagation();
                    onChange(undefined as Value<IsMultiple<T>, Clearable>);
                  }}
                >
                  Clear
                </Button>
              )}
            </>
          )}
        </div>

        {canHaveErrorMessage && <ErrorAccordion error={error} />}
      </div>
      {(Array.isArray(value) ? value.length > 0 : !!value) && showFileList && (
        <>
          {variant !== 'lightBlue' && (
            <FileCardDefault
              defaultFileName={defaultFileName}
              file={value}
              onRemove={onRemoveSingleFile}
            />
          )}
          {variant === 'lightBlue' && (
            <FileCardLightBlue
              defaultFileName={defaultFileName}
              file={value}
              onRemove={onRemoveSingleFile}
            />
          )}
          <div className="mt-5 flex justify-end">
            {Array.isArray(value) && value.length > 0 && (
              <button
                onClick={() =>
                  onChange(undefined as Value<IsMultiple<T>, Clearable>)
                }
                type="button"
                className="self-end rounded-lg bg-neutral-200 p-2 text-body-small text-neutral-1000"
              >
                Delete all
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default FileInput;

type FileCardLightBlueProps = {
  file: File | Blob | File[] | Blob[] | undefined;
  onRemove: (file: File | Blob) => void;
  defaultFileName: string;
};
const FileCardLightBlue: FC<FileCardLightBlueProps> = ({
  file,
  onRemove,
  defaultFileName,
}) => {
  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-1">
        {(Array.isArray(file)
          ? (file as unknown as File[] | Blob[])
          : [file]
        ).map((val, index) => (
          <div
            className="flex items-center justify-between rounded-lg bg-neutral-200 px-6 py-4 text-neutral-1000"
            key={JSON.stringify(index)}
          >
            <div className="flex items-center gap-4">
              <div className="aspect-square rounded-full bg-primary-100 p-2">
                <Icon
                  icon="material-symbols:description-outline"
                  className={classNames(
                    iconStyles({ variant: 'lightBlue' }),
                    'text-xl'
                  )}
                />
              </div>
              <div>
                <Text>{(val as File | Blob)?.name ?? defaultFileName}</Text>
                <Text className="text-neutral-700" variant="body-caption">
                  {(val as File | Blob)?.size &&
                    `${Math.round((val as File | Blob).size / 1024)} KB •`}{' '}
                  Uploaded 100%
                </Text>
              </div>
            </div>
            <Icon
              icon="material-symbols:delete-outline"
              className="cursor-pointer text-xl"
              onClick={() => onRemove(val as File | Blob)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

type FileCardDefaultProps = {
  file: File | Blob | File[] | Blob[] | undefined;
  onRemove: (file: File | Blob) => void;
  defaultFileName: string;
};
const FileCardDefault: FC<FileCardDefaultProps> = ({
  file,
  onRemove,
  defaultFileName,
}) => {
  return (
    <div className="flex flex-col">
      <div className="mt-1 flex items-center gap-3 rounded-t-lg border border-success p-2 text-neutral-1000">
        <Icon
          icon="material-symbols-light:check-circle-outline"
          className="text-xl text-success"
        />{' '}
        You have succesfully uploaded the file.
      </div>
      <div className="scroll-transparent flex max-h-32 flex-col gap-1 overflow-auto rounded-b-lg border-x border-b border-neutral-500 p-2">
        {(Array.isArray(file)
          ? (file as unknown as File[] | Blob[])
          : [file]
        ).map((val, index) => (
          <div
            className="flex items-center justify-between rounded-lg bg-neutral-200 p-2 text-neutral-1000"
            key={JSON.stringify(index)}
          >
            <span>{(val as File | Blob)?.name ?? defaultFileName}</span>
            <Icon
              icon="material-symbols:delete-outline"
              className="cursor-pointer text-xl"
              onClick={() => onRemove(val as File | Blob)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
