import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import classNames from 'classnames';
import {
  type DetailedHTMLProps,
  type ForwardedRef,
  forwardRef,
  type InputHTMLAttributes,
} from 'react';

import ErrorAccordion from '@/components/ErrorAccordion';

import type { ErrorDetailsProps } from '../ArrayDataNestedForm';
import Label from './Label';

const optionContainerStyles = (error?: string) =>
  cva('flex rounded-lg transition-colors', {
    variants: {
      variant: {
        primary: classNames('bg-primary-20%  border-2 border-primary-700', {
          '!border-error': !!error,
        }),
        candidateSimple: classNames('text-neutral-1000', {
          '!text-error': !!error,
        }),
        dashboard: classNames('text-jb-neutral-300', {
          '!text-error': !!error,
        }),
      },
    },
    defaultVariants: {
      variant: 'primary',
    },
  });

type Props = {
  label?: string;
  options: { label: string; value: string }[];
  values: string[];
  required?: boolean;
  checkboxClassName?: string;
  labelClassName?: string;
  labelTitleClassName?: string;
  checkboxCheckIconColor?: 'black' | 'white';
  checkboxValueClassName?: (checked: boolean) => string;
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> &
  ErrorDetailsProps<string> &
  VariantProps<ReturnType<typeof optionContainerStyles>>;

const CheckboxList = (
  {
    options,
    label,
    values,
    required = false,
    error,
    checkboxClassName,
    labelClassName,
    labelTitleClassName,
    canHaveErrorMessage = true,
    checkboxCheckIconColor = 'black',
    checkboxValueClassName,
    variant,
    ...props
  }: Props,
  ref: ForwardedRef<HTMLInputElement>
) => {
  return (
    <div className="flex flex-col gap-1">
      {label && (
        <Label className={labelTitleClassName} required={required}>
          {label}
        </Label>
      )}
      <div className="flex flex-col gap-2">
        {options.map((option) => (
          <div
            key={option.value}
            className={classNames(
              optionContainerStyles(error)({ variant }),
              checkboxClassName
            )}
          >
            <label
              htmlFor={option.value + props.name}
              className={classNames(
                'flex flex-1 cursor-pointer items-center p-4',
                labelClassName
              )}
            >
              <input
                className="hidden"
                type="checkbox"
                id={option.value + props.name}
                value={option.value}
                {...props}
                ref={ref}
              />
              <span
                className={classNames(
                  'mr-4 inline-flex h-5 w-5 items-center justify-center rounded-sm border-2',
                  checkboxValueClassName?.(values.includes(option.value))
                )}
              >
                {values && values.includes(option.value) && (
                  <FontAwesomeIcon
                    icon={faCheck}
                    color={checkboxCheckIconColor}
                  />
                )}
              </span>
              {option.label}
            </label>
          </div>
        ))}
        {canHaveErrorMessage && <ErrorAccordion error={error} />}
      </div>
    </div>
  );
};

export default forwardRef(CheckboxList);
