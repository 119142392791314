import { z } from 'zod';

import authService from '@/services/auth/auth';

import { apiRoutes } from './config';

const geoSuggestionSchema = z.object({
  mainText: z.string(),
  secondaryText: z.string(),
});

export const getGeoSuggestion = async (address: string) => {
  const { data } = await authService.get(apiRoutes.geoSuggestion._root, {
    params: { address },
  });
  return z.array(geoSuggestionSchema).parse(data);
};
