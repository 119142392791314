import { createQueryKeys } from '@lukemorales/query-key-factory';
import { z } from 'zod';

import {
  getCommunityProfile,
  getCommunityTags,
  getHotTopics,
  getMyCommunityProfile,
  getPostDetail,
  getPosts,
  PostsOrderingEnum,
} from '@/services/community';
import { asArray } from '@/utils/asArray';

export const communityPostsSchema = z.object({
  tags: asArray(z.coerce.number()).optional().catch([]),
  query: z.string().optional().catch(undefined),
  ordering: z.nativeEnum(PostsOrderingEnum).optional().catch(undefined),
});
export type CommunityPosts = z.infer<typeof communityPostsSchema>;

export const community = createQueryKeys('community', {
  myProfile: {
    queryKey: null,
    queryFn: getMyCommunityProfile,
  },
  profile: (id: number) => ({
    queryKey: [id],
    queryFn: () => getCommunityProfile(id),
  }),
  list: ({ tags, query, ordering }: CommunityPosts) => ({
    queryKey: [tags, query, ordering],
    queryFn: ({ pageParam }) =>
      getPosts({
        offset:
          // eslint-disable-next-line no-unsafe-optional-chaining
          pageParam * 10,
        pageSize: 10,
        tags,
        query,
        ordering,
      }),
  }),
  detail: ({ id, ordering }: Parameters<typeof getPostDetail>[0]) => ({
    queryKey: [id, ordering],
    queryFn: () => getPostDetail({ id, ordering }),
  }),
  tags: {
    queryKey: null,
    queryFn: getCommunityTags,
  },
  hotTopics: {
    queryKey: null,
    queryFn: getHotTopics,
  },
});
