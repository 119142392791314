import { createQueryKeys } from '@lukemorales/query-key-factory';

import {
  getCandidateProfileDetail,
  getCandidateProfileMe,
} from '@/services/candidateProfile';

export const candidateProfile = createQueryKeys('candidateProfile', {
  me: {
    queryKey: null,
    queryFn: getCandidateProfileMe,
  },
  detail: (id: number) => ({
    queryKey: [id],
    queryFn: () => getCandidateProfileDetail(id),
  }),
});
