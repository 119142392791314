import { useSkillsLocalStorage } from '@/local_storage/useSkillsLocalStorage';

import { useSkills } from './useSkills';

export const useSkillsOptions = () => {
  const { data } = useSkills();
  const { hardSkills, softSkills, ...localStorageSkills } =
    useSkillsLocalStorage();

  const hardSkillsOptions = Array.from(
    new Set([...(data?.results.map(({ name }) => name) ?? []), ...hardSkills])
  ).map((value) => ({ value, label: value }));

  const softSkillsOptions = softSkills.map((value) => ({
    value,
    label: value,
  }));

  return {
    ...localStorageSkills,
    softSkillsOptions,
    hardSkillsOptions,
  };
};
