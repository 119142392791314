import axios from 'axios';
import QueryString from 'qs';
import { z } from 'zod';

import type { SeniorityLevel, WorkType } from '@/stores/useJobBoardStore';
import { SalaryPeriod } from '@/stores/useJobBoardStore';

import { DjangoDataZodSchema } from './common';
import { apiRoutes } from './config';

const seniorityLevelSchema = z.enum(['INTERN', 'JUNIOR', 'MID', 'SENIOR']);
export enum CompanyProfileSizeEnum {
  UNKNOWN = 'INKNOWN',
  '1_50' = '1_50',
  '51_200' = '51_200',
  '201_500' = '201_500',
  '501_1000' = '501_1000',
  '1001_5000' = '1001_5000',
  '5001_10000' = '5001_10000',
  'OVER_10000' = 'OVER_10000',
}
export enum CompanyProfileRevenueEnum {
  UNKNOWN = 'UNKNOWN',
  UNDER_100K = 'UNDER_100K',
  '100K_1M' = '100K_1M',
  '1M_10M' = '1M_10M',
  'OVER_10M' = 'OVER_10M',
}
const imageSchema = z
  .object({
    url: z.string(),
    width: z.number(),
    height: z.number(),
  })
  .nullable();

const externalOfferSchema = z.object({
  id: z.number(),
  jobId: z.string(),
  link: z.string(),
  companyName: z.string(),
  jobTitle: z.string(),
  location: z.string().nullable(),
  locations: z.array(z.string()),
  salaryLower: z.number().optional().nullable(),
  salaryUpper: z.number().optional().nullable(),
  salaryPeriod: z.nativeEnum(SalaryPeriod).nullable(),
  jobDescriptionText: z.string(),
  jobDescriptionTextWithStyling: z.string(),
  shortDescription: z.string().nullable().nullable(),
  requirements: z.array(z.string()),
  skills: z.array(z.string()),
  benefits: z.string().nullable(),
  isOnsite: z.boolean(),
  isHybrid: z.boolean(),
  isRemote: z.boolean(),
  seniorityLevel: seniorityLevelSchema,
  companyImage: imageSchema,
  createdAt: z.string(),
});

const CommonCompanyProfileSchema = z.object({
  name: z.string(),
  image: z.string().url().nullable(),
  link: z.string().url(),
  avgRating: z.number(),
  ratingCount: z.number(),
  bulletCount: z.number(),
});

const companyProfileSchema = CommonCompanyProfileSchema.extend({
  id: z.number(),
});
export type CompanyProfile = z.infer<typeof companyProfileSchema>;

const companyProfileDetailSchema = CommonCompanyProfileSchema.extend({
  linkMainSite: z.string().nullable(),
  mainLocation: z.string().nullable(),
  type: z.string().nullable(),
  founded: z.number().nullable(),
  size: z.nativeEnum(CompanyProfileSizeEnum),
  revenue: z.nativeEnum(CompanyProfileRevenueEnum),
});
export type CompanyProfileDetail = z.infer<typeof companyProfileDetailSchema>;

const externalOfferDetailSchema = externalOfferSchema.extend({
  companyDescription: z.string().nullable(),
});
export type SeniorityLevelTuple = z.infer<typeof seniorityLevelSchema>;
export type ExternalOffer = z.infer<typeof externalOfferSchema>;

const companySchema = z.object({
  name: z.string(),
  companyImage: imageSchema,
});

export const getExternalOffers = async ({
  limit,
  offset,
  search,
  location,
  workType,
  seniorityLevel,
  salaryLower,
  salaryUpper,
  ordering,
  companyName,
}: {
  offset: number;
  limit: number;
  search?: string;
  location?: string;
  workType?: WorkType[];
  seniorityLevel?: SeniorityLevel[];
  salaryLower?: string;
  salaryUpper?: string;
  ordering?: string;
  companyName?: string;
}) => {
  const { data } = await axios.get(
    apiRoutes['external-job-offers'].automation._root,
    {
      params: {
        offset,
        limit,
        search,
        location,
        work_type: workType,
        seniority_level: seniorityLevel,
        salary_lower: salaryLower,
        salary_upper: salaryUpper,
        ordering,
        company_name: companyName,
      },
      paramsSerializer: (params) => {
        return QueryString.stringify(params, { arrayFormat: 'repeat' });
      },
    }
  );
  return DjangoDataZodSchema(externalOfferSchema).parse(data);
};

export const getExternalOfferDetail = async (id: string) => {
  const { data } = await axios.get(
    apiRoutes['external-job-offers'].automation.detail(id)
  );
  return externalOfferDetailSchema.parse(data);
};

export const getSearchSuggestions = async (
  type: 'LOCATION' | 'JOB_TITLE',
  query: string
) => {
  const { data } = await axios.get(
    apiRoutes['external-job-offers'].automation.searchSuggestions,
    {
      params: {
        search_type: type,
        query,
      },
    }
  );
  return z.array(z.string()).parse(data);
};

export const getCompanies = async () => {
  const { data } = await axios.get(
    apiRoutes['external-job-offers'].automation.companies
  );

  return z.array(companySchema).parse(data);
};

export const getCompaniesProfile = async ({
  pageSize = 999,
  offset = 0,
  query,
}: {
  pageSize?: number;
  offset?: number;
  query?: string;
}) => {
  const { data } = await axios.get(
    apiRoutes['external-job-offers'].company._root,
    {
      params: {
        limit: pageSize,
        offset,
        query,
      },
    }
  );
  return DjangoDataZodSchema(companyProfileSchema).parse(data);
};

export const getCompaniesProfileSuggestions = async ({
  pageSize = 10,
  offset = 0,
  query,
}: {
  pageSize?: number;
  offset?: number;
  query?: string;
}) => {
  const { data } = await axios.get(
    apiRoutes['external-job-offers'].company.searchSuggestions,
    {
      params: {
        limit: pageSize,
        offset,
        query,
      },
    }
  );
  return z.array(companyProfileSchema).parse(data);
};

export const getCompanyProfile = async (id: number) => {
  const { data } = await axios.get(
    apiRoutes['external-job-offers'].company.detail(id)
  );
  return companyProfileDetailSchema.parse(data);
};
