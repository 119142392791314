import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { LayoutGroup, motion } from 'framer-motion';
import { useRouter } from 'next/router';
import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';

import BlockPageWithoutPhoneNumber from '@/components/BlockPageWithoutPhoneNumber';
import Button from '@/components/Button';
import AddCVForm from '@/components/CVGenerator/AddCVForm';
import PaginatedQueryControls from '@/components/CVGenerator/PaginatedQueryControls';
import AdvancedSearchLoading from '@/components/Dashboard/AdvancedSearchLoading';
import Collections from '@/components/Dashboard/Collections';
import DashboardControls from '@/components/Dashboard/DashboardControls';
import Navigation from '@/components/Dashboard/navigation/Navigation';
import DashboardTableLayout from '@/components/Dashboard/TableLayout';
import FileInput from '@/components/Form/Fields/FileInput';
import Text from '@/components/Text';
import { Dialog, DialogContent, DialogHeader } from '@/components/ui/dialog';
import { DocumentsFileTypes } from '@/config/filesTypes';
import { queries } from '@/queries';
import {
  getReasoningSearch,
  postReasoningSearch,
} from '@/services/reasoningSearch';
import { useDashboard } from '@/stores/useDashboardStore';
import { getNumberParams } from '@/utils/queryParams';

const CVGenerator = () => {
  const router = useRouter();
  const queryClient = useQueryClient();

  const [modalOpen, setModalOpen] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const { query } = useRouter();
  const { pagination, setSelectedContexts } = useDashboard();
  const {
    pagination: { pageIndex, pageSize },
    setParams,
    setSearchType,
    setSearch,
    search,
    searchType,
  } = useDashboard();

  const { collection: collectionParam } = router.query;
  const parsedCollection = getNumberParams(collectionParam);
  const collections = parsedCollection ? [parsedCollection] : [];

  const { data: reasoningSearchPostResult } = useQuery({
    queryFn: () => postReasoningSearch(search),
    queryKey: ['reasoningSearchPost', search],
    enabled: searchType === 'advanced' && search.length > 0,
    staleTime: Infinity,
  });

  const { data: reasoningSearchResult } = useQuery({
    queryFn: () => getReasoningSearch(reasoningSearchPostResult!.id),
    queryKey: ['reasoningSearch', reasoningSearchPostResult?.id],
    enabled: !!reasoningSearchPostResult?.id,
    refetchInterval: (queryData) => {
      if (!queryData) return false;

      if (Object.values(queryData.processingState).every((v) => v === true))
        return false;
      return 1000;
    },
  });

  const { data } = useQuery({
    ...queries.externalCandidates.list({
      search,
      collections,
      pagination,
      filtersId:
        reasoningSearchResult?.processingState.characteristicsCompleted &&
        searchType === 'advanced'
          ? reasoningSearchResult?.id
          : undefined,
    }),
    staleTime: 5 * 60 * 1000,
    keepPreviousData: true,
  });

  useEffect(() => {
    setSelectedContexts([]);
    setParams({ page: 0, pageSize: pagination.pageSize });
  }, [JSON.stringify(collections)]);

  useEffect(() => {
    if (router.query.actions === 'open-add-candidate') {
      setModalOpen(true);
    }
    if (router.query.actions) {
      router.replace({
        pathname: router.pathname,
        query: { ...router.query, actions: undefined },
      });
    }
  }, [router]);

  const candidatesCount = data?.count;

  return (
    <BlockPageWithoutPhoneNumber>
      <LayoutGroup>
        <motion.div className="flex flex-1 overflow-auto overflow-x-hidden">
          <div className="flex max-h-screen w-full flex-1 flex-col overflow-auto">
            <div
              data-joyride="dashboard"
              className="flex flex-1 flex-col overflow-hidden"
            >
              <div className="mb-4 flex gap-3 ">
                <DashboardControls
                  setSearchType={setSearchType}
                  onSearchChange={setSearch}
                />
              </div>
              <div className="flex flex-row gap-4">
                <Collections />
                <div className="flex h-fit flex-1 items-center gap-4">
                  <Text variant="body-caption" className="whitespace-nowrap">
                    Candidates: {candidatesCount}
                    {(data as any)?.candidatesCount?.filteredCandidates && (
                      <>
                        {' '}
                        <span className="text-primary-400">
                          (Best:{' '}
                          {(data as any)?.candidatesCount?.filteredCandidates}
                        </span>{' '}
                        and{' '}
                        <span className="text-primary-400">
                          Suggested:{' '}
                          {(data as any)?.candidatesCount?.semanticCandidates})
                        </span>
                      </>
                    )}
                  </Text>
                  <Button
                    variant="whiteOutline"
                    onClick={() => setModalOpen(true)}
                    size="sm"
                    prefixIcon={faPlus}
                    className="whitespace-nowrap py-1"
                    data-joyride="add-candidate"
                  >
                    Add candidate
                  </Button>
                </div>
              </div>

              <DashboardTableLayout
                collections={collections}
                search={search}
                filtersId={
                  reasoningSearchResult?.processingState
                    .characteristicsCompleted && searchType === 'advanced'
                    ? reasoningSearchResult?.id
                    : undefined
                }
              />
            </div>
            <div className="flex self-center pb-6 pt-4">
              <div className="flex items-center gap-2">
                <Text
                  variant="body-small"
                  className="min-w-20 whitespace-nowrap"
                >
                  Page{' '}
                  <span className="font-semibold text-primary-300">
                    {pageIndex + 1}
                  </span>{' '}
                  of{' '}
                  <span className="font-semibold text-primary-300">
                    {Math.ceil((candidatesCount ?? 0) / pageSize) || 1}
                  </span>
                </Text>
                <div className="flex gap-2">
                  <PaginatedQueryControls
                    data={data}
                    page={pageIndex}
                    pageSize={pageSize}
                    onPageChange={(newPage) =>
                      setParams({ page: newPage, pageSize })
                    }
                    onPageSizeChange={(newPageSize) =>
                      setParams({
                        pageSize: newPageSize,
                        page: 0,
                      })
                    }
                    onPrefetch={(controlsPagination) =>
                      queryClient.prefetchQuery({
                        ...queries.cvGenerator.list({
                          search,
                          pagination: controlsPagination,
                        }),
                        staleTime: 1000 * 60 * 5,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            {!query.setting && (
              <FileInput
                name="cv-dnd"
                className={(isDragging) => [
                  { hidden: !isDragging || modalOpen },
                ]}
                variant="primary"
                isButtonStyled
                onChange={(value) => {
                  setFiles(value as File[]);
                  setModalOpen(true);
                }}
                multiple
                accept={DocumentsFileTypes}
              />
            )}
            <Dialog
              open={modalOpen}
              onOpenChange={(open) => {
                setModalOpen(open);
                if (!open) setFiles([]);
              }}
            >
              <DialogContent className="w-full max-w-2xl">
                <DialogHeader>Add candidate</DialogHeader>
                <AddCVForm
                  defaultCollections={
                    parsedCollection ? [parsedCollection] : undefined
                  }
                  defaultValue={files}
                  onRequestClose={() => setModalOpen(false)}
                />
              </DialogContent>
            </Dialog>
          </div>
        </motion.div>
        {reasoningSearchResult && (
          <AdvancedSearchLoading
            loadingState={reasoningSearchResult?.processingState}
            dialogProps={{
              open: Object.values(reasoningSearchResult.processingState).some(
                (state) => !state
              ),
            }}
          />
        )}
      </LayoutGroup>
    </BlockPageWithoutPhoneNumber>
  );
};

CVGenerator.getLayout = (page: ReactElement) => (
  <Navigation showFilters>{page}</Navigation>
);

export default CVGenerator;
