import { createQueryKeys } from '@lukemorales/query-key-factory';

import { getGeoSuggestion } from '@/services/geoSuggestion';

export const geoSuggestion = createQueryKeys('geoSuggestion', {
  list: (address: string) => ({
    queryKey: [address],
    queryFn: () => getGeoSuggestion(address),
  }),
});
