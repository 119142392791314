import { faAngleRight, faPlus, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from '@iconify/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { ChevronDown } from 'lucide-react';
import { type FC, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import type { InferType } from 'yup';
import { array, number, object, string, tuple } from 'yup';

import useForm from '@/hooks/useForm';
import { useLanguages } from '@/hooks/useLanguages';
import { useOptimisticUpdate } from '@/hooks/useOptimisticUpdate';
import { useSkillsOptions } from '@/hooks/useSkillsOptions';
import { queries } from '@/queries';
import {
  availabilityOptions,
  experienceOptions,
  jobStartOptions,
} from '@/services/externalCandidates';
import {
  getReasoningSearch,
  patchReasoningSearch,
  postReasoningSearch,
  transformApiOfferSearch,
} from '@/services/reasoningSearch';
import { useDashboard } from '@/stores/useDashboardStore';
import { capitalizeFirstLetter } from '@/utils/cvGenerator';

import Button from '../Button';
import CheckboxList from '../Form/Fields/CheckboxList';
import Label from '../Form/Fields/Label';
import SelectValuePair from '../Form/Fields/SelectValuePair';
import { Select } from '../Select';
import Text from '../Text';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../ui/accordion';

export const LANGUAGES_LEVEL_OPTIONS = [
  { label: 'Basic', value: 0 },
  { label: 'Intermediate', value: 1 },
  { label: 'Advanced', value: 2 },
  { label: 'Near-native', value: 3 },
  { label: 'Native', value: 4 },
];

const DashboardFilters: FC<{}> = () => {
  const queryClient = useQueryClient();
  const [navigationExpanded, setNavigationExpanded] = useState(false);
  const { search, searchType } = useDashboard();

  const { data: reasoningSearchPostResult } = useQuery({
    queryFn: () => postReasoningSearch(search),
    queryKey: ['reasoningSearchPost', search],
    enabled: searchType === 'advanced' && search.length > 0,
    staleTime: Infinity,
  });

  const { setOptimisticData } = useOptimisticUpdate<
    Awaited<ReturnType<typeof getReasoningSearch>>
  >(['reasoningSearch', reasoningSearchPostResult?.id]);

  const { data: reasoningSearchResult } = useQuery({
    queryFn: () => getReasoningSearch(reasoningSearchPostResult!.id),
    queryKey: ['reasoningSearch', reasoningSearchPostResult?.id],
    enabled: !!reasoningSearchPostResult?.id,
    refetchInterval: (queryData) => {
      if (!queryData) return false;

      if (Object.values(queryData.processingState).every((v) => v === true))
        return false;
      return 1000;
    },
  });

  const [localSkill, setLocalSkill] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const {
    register,
    watch,
    reset,
    control,
    handleSubmit,
    setValue,
    formState: { isDirty },
  } = useForm<InferType<typeof schema>>({
    defaultValues: reasoningSearchResult?.aiParsedFilters,
  });

  const { data: languages } = useLanguages();
  const { hardSkillsOptions } = useSkillsOptions();

  const { data: contriesData } = useQuery({
    ...queries.common.countries(undefined),
  });
  const { data: citiesData } = useQuery({
    ...queries.common.cities(watch('country')),
    enabled: !!watch('country'),
  });

  const { mutate } = useMutation({
    mutationFn: patchReasoningSearch,
    onSuccess: (response) => {
      queryClient.invalidateQueries([
        ...queries.externalCandidates.list._def,
        { filtersId: reasoningSearchPostResult!.id },
      ]);
      setOptimisticData(() => transformApiOfferSearch(response));
    },
  });
  const languagesOptions = languages?.results.map((val) => ({
    label: val.name,
    value: val.id,
  }));

  const countriesOptions = contriesData?.results.map((country) => ({
    value: country.id,
    label: country.name,
  }));

  const citiesOptions = citiesData?.results.map((city) => ({
    value: city.id,
    label: city.name,
  }));

  const onReset = () => {
    reset(reasoningSearchResult?.aiParsedFilters);
  };

  useEffect(() => {
    onReset();
  }, [JSON.stringify(reasoningSearchResult?.aiParsedFilters)]);

  useEffect(() => {
    setValue('city', null);
  }, [watch('country')]);

  if (
    !reasoningSearchResult ||
    JSON.stringify(watch()) === JSON.stringify({}) ||
    searchType !== 'advanced'
  )
    return null;

  return (
    <motion.div
      animate={{ width: navigationExpanded ? '25rem' : '4rem' }}
      className={classNames(
        'z-10 h-full overflow-y-auto overflow-x-hidden bg-neutral-1000 px-4 py-6',
        { 'overflow-y-hidden': !navigationExpanded }
      )}
    >
      <AnimatePresence>
        {!navigationExpanded && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute top-40"
          >
            <Icon
              icon="material-symbols-light:filter-alt"
              className="text-3xl"
            />
          </motion.div>
        )}
      </AnimatePresence>
      <motion.div
        animate={{ width: navigationExpanded ? '25rem' : '4rem' }}
        className="absolute"
      >
        <button
          type="button"
          className="absolute right-0 top-[8.5rem] h-8 w-6  rounded-[0.25rem] bg-primary-800"
          onClick={() => setNavigationExpanded((prev) => !prev)}
        >
          <FontAwesomeIcon
            icon={faAngleRight}
            className={classNames('transition-transform', {
              'rotate-180': navigationExpanded,
            })}
          />
        </button>
      </motion.div>
      <AnimatePresence>
        {navigationExpanded && (
          <motion.div
            animate={{
              opacity: 1,
            }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            className=""
          >
            <Text variant="jb-body-medium">
              ({reasoningSearchResult.keywords.keywords.length}) Detected
              Filters
            </Text>
            <div className="flex flex-wrap gap-2 py-4">
              {reasoningSearchResult.keywords.keywords.map((keyword) => (
                <div
                  key={keyword}
                  className="w-min whitespace-nowrap rounded-lg border border-neutral-600 bg-neutral-800 p-2"
                >
                  <Text variant="jb-body-medium">
                    {capitalizeFirstLetter(keyword)}
                  </Text>
                </div>
              ))}
            </div>
            <div className="mb-4 h-px w-full bg-neutral-700" />
            <Accordion defaultValue="filters" type="single" collapsible>
              <AccordionItem
                className="flex flex-col gap-2 border-b-0"
                value="filters"
              >
                <AccordionTrigger
                  className="[&[data-state=open]_svg]:rotate-180"
                  asChild
                >
                  <button
                    type="button"
                    className="rounded-lg border border-neutral-700 bg-neutral-900 px-2 hover:!no-underline"
                  >
                    Filters
                    <ChevronDown className="h-4 w-4 shrink-0 transition-transform duration-200" />
                  </button>
                </AccordionTrigger>
                <AccordionContent>
                  <form
                    className="rounded-lg border border-neutral-700 bg-neutral-900 p-2"
                    onSubmit={handleSubmit((data) =>
                      mutate({
                        id: reasoningSearchPostResult!.id,
                        formData: {
                          ...reasoningSearchResult,
                          aiParsedFilters: {
                            ...reasoningSearchResult?.aiParsedFilters,
                            ...data,
                          },
                        },
                      })
                    )}
                  >
                    <div className="gap-2 divide-y divide-neutral-700  [&_>*:first-child]:!pt-0 [&_>*:last-child]:!pb-0 [&_>*]:py-4">
                      <CheckboxList
                        {...register('seniority')}
                        values={watch('seniority') ?? []}
                        variant="dashboard"
                        checkboxCheckIconColor="white"
                        label="Experience Level"
                        options={experienceOptions}
                        labelClassName="text-jb-body-medium !p-0"
                        checkboxValueClassName={(checked) =>
                          classNames(
                            'bg-neutral-100 border-[#AAAAAA] transition-colors !rounded-md',
                            {
                              '!bg-primary-700 border-primary-700': checked,
                            }
                          )
                        }
                      />
                      <CheckboxList
                        values={watch('jobStart')}
                        {...register('jobStart')}
                        label="Job Start"
                        variant="dashboard"
                        checkboxCheckIconColor="white"
                        options={jobStartOptions}
                        labelClassName="text-jb-body-medium !p-0"
                        checkboxValueClassName={(checked) =>
                          classNames(
                            'bg-neutral-100 border-[#AAAAAA] transition-colors !rounded-md',
                            {
                              '!bg-primary-700 border-primary-700': checked,
                            }
                          )
                        }
                      />
                      <CheckboxList
                        values={watch('availability')}
                        {...register('availability')}
                        label="Availability"
                        variant="dashboard"
                        checkboxCheckIconColor="white"
                        options={availabilityOptions}
                        labelClassName="text-jb-body-medium !p-0"
                        checkboxValueClassName={(checked) =>
                          classNames(
                            'bg-neutral-100 border-[#AAAAAA] transition-colors !rounded-md',
                            {
                              '!bg-primary-700 border-primary-700': checked,
                            }
                          )
                        }
                      />
                      <CheckboxList
                        values={watch('relocation')}
                        {...register('relocation')}
                        label="Willingness to Relocate"
                        variant="dashboard"
                        checkboxCheckIconColor="white"
                        options={[
                          { label: 'Yes', value: 'Yes' },
                          { label: 'No', value: 'No' },
                        ]}
                        labelClassName="text-jb-body-medium !p-0"
                        checkboxValueClassName={(checked) =>
                          classNames(
                            'bg-neutral-100 border-[#AAAAAA] transition-colors !rounded-md',
                            {
                              '!bg-primary-700 border-primary-700': checked,
                            }
                          )
                        }
                      />
                      <div className="flex gap-2">
                        <Controller
                          control={control}
                          name="country"
                          render={({ field: { onChange, value } }) => (
                            <Select
                              isClearable
                              variant="grayWhiteText"
                              label="Country"
                              value={
                                countriesOptions?.find(
                                  (country) => country.value === value
                                ) ?? null
                              }
                              onChange={(newValue) => onChange(newValue?.value)}
                              options={countriesOptions}
                              menuPortalTarget={document.querySelector('body')}
                              className="flex-1"
                            />
                          )}
                        />
                        <Controller
                          control={control}
                          name="city"
                          render={({ field: { onChange, value } }) => (
                            <Select
                              isClearable
                              variant="gray"
                              label="City"
                              value={
                                citiesOptions?.find(
                                  (country) => country.value === value
                                ) ?? null
                              }
                              onChange={(newValue) => onChange(newValue?.value)}
                              options={citiesOptions}
                              menuPortalTarget={document.querySelector('body')}
                              classNames={{
                                singleValue: () =>
                                  'text-jb-body-medium text-white',
                              }}
                              className="flex-1"
                            />
                          )}
                        />
                      </div>
                      <Controller
                        control={control}
                        name="languages"
                        render={({ field: { onChange, value } }) => (
                          <SelectValuePair
                            mainLabel="Languages"
                            type="select"
                            placeholders={['Language', 'Level']}
                            options={languagesOptions ?? []}
                            secondOptions={LANGUAGES_LEVEL_OPTIONS}
                            onChange={onChange}
                            value={value.length >= 1 ? value : [[null, null]]}
                            addButtonsVariant="neutral300"
                            deleteButtonsVariant="neutral300"
                            selectVariant="grayWhiteText"
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name="skills"
                        render={({ field: { onChange, value } }) => (
                          <div className="flex flex-col gap-4">
                            <Label className="-mb-2">Skills</Label>
                            <div className="flex items-stretch gap-2">
                              <Select
                                isClearable
                                variant="grayWhiteText"
                                onChange={(newSkill) => setLocalSkill(newSkill)}
                                value={localSkill}
                                options={hardSkillsOptions}
                                classNames={{
                                  control: () => 'w-full',
                                }}
                                className="flex-1"
                                menuPlacement="top"
                                menuPortalTarget={document.querySelector(
                                  'body'
                                )}
                              />
                              <button
                                disabled={localSkill === null}
                                type="button"
                                className="rounded-[0.25rem] bg-neutral-300 p-2"
                                onClick={() => {
                                  onChange([...value, localSkill?.label ?? '']);
                                  setLocalSkill(null);
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faPlus}
                                  className="text-black"
                                />
                              </button>
                            </div>
                            <div className="flex flex-wrap gap-2">
                              {value.map((skill) => (
                                <div
                                  key={skill}
                                  className="flex w-min gap-2 whitespace-nowrap rounded-lg border border-neutral-600 bg-neutral-800 p-2"
                                >
                                  {skill}
                                  <button
                                    onClick={() =>
                                      onChange(
                                        value.filter(
                                          (skillValue) => skillValue !== skill
                                        )
                                      )
                                    }
                                    type="button"
                                  >
                                    <FontAwesomeIcon icon={faX} />
                                  </button>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      />
                    </div>
                    <AnimatePresence>
                      {isDirty && (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          className="sticky -bottom-6 -mx-2 -mb-2 mt-4 flex bg-neutral-1000/90 px-2 py-4 backdrop-blur-sm"
                        >
                          <Button
                            onClick={onReset}
                            className="w-full"
                            variant="unstyled"
                          >
                            Cancel
                          </Button>
                          <Button className="w-full" type="submit">
                            Apply Filter
                          </Button>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </form>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default DashboardFilters;

const schema = object({
  jobStart: array().of(string().defined()).defined(),
  availability: array().of(string().defined()).defined(),
  seniority: array().of(string().defined()).defined(),
  relocation: array().of(string().defined()).defined(),
  country: number().nullable().defined(),
  city: number().nullable().defined(),
  languages: array()
    .of(tuple([number().defined(), number().defined()]).defined())
    .defined(),
  skills: array().of(string().defined()).defined(),
});
