import { createQueryKeys } from '@lukemorales/query-key-factory';
import type { PaginationState } from '@tanstack/react-table';

import {
  getCompanies,
  getCompaniesProfile,
  getCompaniesProfileSuggestions,
  getExternalOffers,
  getSearchSuggestions,
} from '@/services/external-offers';

export const externalOffers = createQueryKeys('externalOffers', {
  list: ({
    pagination,
    location,
    salaryLower,
    salaryUpper,
    search,
    seniorityLevel,
    workType,
    ordering,
    companyName,
  }: Omit<Parameters<typeof getExternalOffers>[0], 'limit' | 'offset'> & {
    pagination?: PaginationState;
  }) => ({
    queryFn: ({ pageParam }) =>
      getExternalOffers({
        limit: pagination?.pageSize ?? 20,
        offset:
          (pagination?.pageIndex ?? pageParam) * (pagination?.pageSize ?? 20),
        search,
        location,
        workType,
        seniorityLevel,
        salaryLower: salaryLower?.toString(),
        salaryUpper: salaryUpper?.toString(),
        ordering,
        companyName,
      }),
    queryKey: [
      'externalOffers',
      location,
      search,
      workType,
      seniorityLevel,
      salaryLower,
      salaryUpper,
      ordering,
      companyName,
    ],
  }),
  suggestedSearch: (type: 'LOCATION' | 'JOB_TITLE', query: string) => ({
    queryFn: () => getSearchSuggestions(type, query),
    queryKey: [type, query],
  }),
  companies: {
    queryKey: null,
    queryFn: getCompanies,
  },
  suggestedCompanyProfileList: ({
    pageSize,
    offset,
    query,
  }: {
    pageSize?: number;
    offset?: number;
    query?: string;
  }) => ({
    queryFn: () => getCompaniesProfileSuggestions({ pageSize, offset, query }),
    queryKey: [pageSize, offset, query],
  }),
  companyProfileList: ({
    query,
    pagination,
  }: {
    query?: string;
    pagination?: PaginationState;
  }) => ({
    queryFn: ({ pageParam }) =>
      getCompaniesProfile({
        pageSize: pagination?.pageSize ?? 20,
        offset:
          (pagination?.pageIndex ?? pageParam) * (pagination?.pageSize ?? 20),
        query,
      }),
    queryKey: [query],
  }),
});
