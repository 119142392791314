/* eslint-disable unused-imports/no-unused-vars */
import {
  faEdit,
  faGear,
  faPlus,
  faRotateRight,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from '@iconify/react';
import {
  Popover,
  PopoverTrigger,
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
} from '@mindpal-co/mindpal-ui';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type {
  CellContext,
  ColumnOrderState,
  RowData,
  SortingState,
} from '@tanstack/react-table';
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import Link from 'next/link';
import type { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { string } from 'yup';

import { LocalStorage } from '@/config/localStorage';
import { useDashboardOnboarding } from '@/hooks/Onboarding/useDashboardOnboarding';
import { useOnboarding } from '@/hooks/Onboarding/useOnboarding';
import { usePatchExternalCandidate } from '@/hooks/usePatchExternalCandidate';
import { queries } from '@/queries';
import { DjangoDataZodSchema } from '@/services/common';
import {
  type ExternalCandidateList,
  externalCandidateListSchema,
  postRetryExternalCandidate,
} from '@/services/externalCandidates';
import { useDashboard } from '@/stores/useDashboardStore';
import { dateFormatter } from '@/utils/date';
import {
  getSavedColumnOrder,
  getSavedVisibility,
  saveColumnOrder,
  saveVisibility,
} from '@/utils/table';

import type { DashboardParams } from '../../stores/useDashboardStore';
import CircularLoadingIndicator from '../CircularLoadingIndicator';
import ColumnsVisibilityAndOrdering from '../ColumnsVisibilityAndOrdering';
import { downloadUrl } from '../CVGenerator/DownloadButton';
import GenerateCVDialog from '../CVGenerator/GenerateCVDialog';
import Checkbox from '../Form/Fields/Checkbox';
import Joyride from '../Joyride';
import Table from '../Table';
import Text from '../Text';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import { PopoverContent } from '../ui/popover';
import { TooltipContent } from '../ui/tooltip';
import AddCandidateToCollection from './AddCandidateToCollectionDialog';
import ButtonWithLabelOnHover from './ButtonWithLabelOnHover';
import SalaryColumnConfigurationDialog from './SalaryColumnConfigurationDialog';
import SelectModeControls from './SelectModeControls';

declare module '@tanstack/react-table' {
  interface TableMeta<TData extends RowData> {
    updateData: (rowId: string, columnId: string, value: unknown) => void;
    editModeActive: boolean;
    setEditModeActive: Dispatch<SetStateAction<boolean>>;
    onProfilePrefetch: (id: number) => void;
    params: DashboardParams;
  }
}

declare module '@tanstack/react-table' {
  interface ColumnMeta<TData extends RowData, TValue> {
    altHeader?: string;
  }
}

const columnHelper = createColumnHelper<ExternalCandidateList>();

const columns = (onRetry: (id: number) => void) => [
  columnHelper.display({
    id: 'select',
    cell: ({ row }) => {
      return (
        <Checkbox
          {...{
            variant: 'transparent',
            className: '!p-0',
            name: `check${row.index.toString()}`,
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      );
    },
  }),
  columnHelper.accessor('name', {
    header: 'Full name',
    cell: (cellProps) => {
      const { getValue, row } = cellProps;
      if (row.original.status === 'PROCESSING')
        return (
          <Text as="span" className="flex items-center gap-1">
            {getValue()}
            <CircularLoadingIndicator size="sm" />
          </Text>
        );
      if (row.original.status === 'FAILED')
        return (
          <button
            type="button"
            onClick={() => onRetry(row.original.id)}
            className="flex items-center gap-1 text-error"
          >
            Retry
            <FontAwesomeIcon icon={faRotateRight} />
          </button>
        );
      if (cellProps.table.options.meta?.editModeActive)
        return <EditableCell cellProps={cellProps} />;
      return (
        <Link
          title={getValue()}
          onMouseEnter={() =>
            cellProps.table.options.meta?.onProfilePrefetch(Number(row.id))
          }
          href={`/dashboard/${row.id}`}
        >
          <Text
            data-joyride="profile-link"
            className="max-w-[17rem] truncate whitespace-nowrap font-semibold underline"
          >
            {getValue() || 'Not specified'}
          </Text>
        </Link>
      );
    },
  }),

  columnHelper.display({
    id: 'resume',
    header: 'Resume',
    cell: ({ row, table: { getIsSomeRowsSelected } }) => {
      if (row.original.cvContexts.length > 0)
        return (
          <Link
            className="w-fit"
            href={{
              pathname: `/dashboard/generator/${row.original.cvContexts?.[0].id}`,
              query: {
                tabs: row.original.cvContexts.slice(1).map(({ id }) => id),
              },
            }}
          >
            <Icon
              icon="mdi:resume"
              data-joyride="resume"
              className="ml-[-2px] text-2xl"
            />
          </Link>
        );
      return (
        <GenerateCVDialog
          profileId={Number(row.id)}
          dialogTriggerProps={{
            asChild: true,
            disabled:
              getIsSomeRowsSelected() || row.original.status !== 'READY',
          }}
        >
          <ButtonWithLabelOnHover icon={faPlus}>
            Generate
          </ButtonWithLabelOnHover>
        </GenerateCVDialog>
      );
    },
  }),
  columnHelper.accessor('email', {
    header: 'Email',
    cell: (cellProps) => {
      if (cellProps.table.options.meta?.editModeActive)
        return <EditableCell cellProps={cellProps} />;
      return cellProps.getValue();
    },
  }),
  columnHelper.accessor('phoneNumber', {
    header: 'Phone number',
    cell: (cellProps) => {
      if (cellProps.table.options.meta?.editModeActive)
        return <EditableCell type="number" cellProps={cellProps} />;
      return <Text className="truncate">{cellProps.getValue()}</Text>;
    },
  }),
  columnHelper.accessor('collections', {
    header: 'Collections',

    cell: ({ row, getValue }) => {
      const value = getValue();
      if (value.length > 0)
        return (
          <Text className="flex whitespace-nowrap">
            <Link
              replace
              href={{
                pathname: '/dashboard',
                query: { collection: value?.[0].id },
              }}
            >
              <Text
                title={value?.[0].title}
                className="w-full max-w-[150px] truncate"
              >
                {value?.[0].title} {value.length > 1 && ','}
              </Text>
            </Link>{' '}
            {value.length > 1 && (
              <TooltipProvider>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger>
                    <Text
                      as="span"
                      variant="body-caption"
                      className="text-neutral-300"
                    >
                      +{value.length - 1}
                    </Text>
                  </TooltipTrigger>
                  <TooltipContent className="flex flex-col gap-1 rounded-lg">
                    {value.map(({ id, title }) => (
                      <Link
                        replace
                        href={{
                          pathname: 'dashboard',
                          query: { collection: id },
                        }}
                        key={id}
                        className="text-neutral-1000"
                      >
                        {title}
                      </Link>
                    ))}
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}
          </Text>
        );
      return (
        <AddCandidateToCollection
          dialogTriggerProps={{ asChild: true }}
          candidatesId={[Number(row.id)]}
        >
          <ButtonWithLabelOnHover icon={faPlus}>
            Add to collection
          </ButtonWithLabelOnHover>
        </AddCandidateToCollection>
      );
    },
  }),

  columnHelper.accessor('createdAt', {
    header: 'Created at',
    cell: ({ getValue }) =>
      dateFormatter({
        date: getValue(),
        format: { dateStyle: 'short' },
      }),
  }),
  columnHelper.accessor('updatedAt', {
    header: 'Updated at',
    cell: ({ getValue }) =>
      dateFormatter({ date: getValue(), format: { dateStyle: 'short' } }),
  }),
  columnHelper.accessor('notes', {
    header: 'Notes',
    cell: ({ getValue }) => (
      <Popover>
        <PopoverTrigger className="group" disabled={getValue().length <= 0}>
          <Icon
            icon="fluent:note-20-filled"
            className="mt-1 text-xl group-disabled:text-neutral-800"
          />
        </PopoverTrigger>
        <PopoverContent className="scroll-transparent flex max-h-80 w-fit max-w-sm flex-col gap-2 overflow-auto border-none bg-transparent p-0">
          {getValue().map(({ createdAt, text, id }) => (
            <div
              className="rounded-lg bg-neutral-200 p-2  text-neutral-1000"
              key={id}
            >
              <Text className="font-bold">
                {dateFormatter({
                  date: createdAt,
                  format: { dateStyle: 'short' },
                })}
              </Text>
              <Text>{text}</Text>
            </div>
          ))}
        </PopoverContent>
      </Popover>
    ),
  }),
  columnHelper.accessor('convertedSalary', {
    meta: { altHeader: 'Salary' },
    id: 'converted_salary',
    enableSorting: true,
    header: ({ table }) => {
      const period = table.options.meta?.params.targetPeriod;
      const targetCurrency = table.options.meta?.params.targetCurrency;
      const headerLabel = {
        HOUR: 'Hour rate',
        MONTH: 'Month rate',
        YEAR: 'Annual rate',
        default: 'Salary',
      }[period ?? (targetCurrency ? 'MONTH' : 'default')];
      return headerLabel;
    },
    cell: ({ getValue, table, row }) => {
      const salary = getValue();
      const nonConvertedSalary = row.original.salary;
      const currentCurrency = row.original.currency;
      const currentPeriod = row.original.paymentPeriod;
      const targetCurrency = table.options.meta?.params.targetCurrency;
      const targetPeriod = table.options.meta?.params.targetPeriod;
      const currencySymbol = {
        PLN: 'zł',
        EUR: '€',
        USD: '$',
        default: '',
      }[
        targetCurrency ?? (targetPeriod ? 'PLN' : currentCurrency ?? 'default')
      ];

      const rowPeriod = {
        HOUR: '/hr',
        MONTH: '/mo',
        YEAR: '/yr',
        default: '',
      }[currentPeriod ?? 'default'];
      const textWithCurrencySymbol = (selectedSalary: number | null) => {
        if (currencySymbol === 'zł')
          return `${selectedSalary} ${currencySymbol}`;
        return `${currencySymbol} ${selectedSalary}`;
      };
      if (!salary && !nonConvertedSalary) return null;
      if (!targetCurrency && !targetPeriod)
        return (
          <Text className="whitespace-nowrap">{`${textWithCurrencySymbol(
            nonConvertedSalary
          )} ${rowPeriod}`}</Text>
        );
      return (
        <Text className="whitespace-nowrap">
          {textWithCurrencySymbol(salary)}
        </Text>
      );
    },
  }),
  columnHelper.display({
    id: 'settings',
    header: ({ table }) => (
      <div className="flex gap-4">
        <div>
          <ColumnsVisibilityAndOrdering
            hiddenColumnsIds={['select', 'settings']}
            table={table}
          >
            <FontAwesomeIcon icon={faGear} />
          </ColumnsVisibilityAndOrdering>
        </div>
        <FontAwesomeIcon
          icon={table.options.meta?.editModeActive ? faXmark : faEdit}
          onClick={() => table.options.meta?.setEditModeActive((prev) => !prev)}
          className="cursor-pointer"
        />
      </div>
    ),
    cell: ({ row }) => {
      const cvContextsIds = row.original.cvContexts.map(({ id }) => id);
      return (
        <DropdownMenu>
          <DropdownMenuTrigger
            className="group"
            disabled={cvContextsIds.length <= 0}
          >
            <Icon
              icon="ri:more-2-fill"
              className="mt-1 group-disabled:text-neutral-800"
            />
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-fit">
            <a href={downloadUrl(cvContextsIds, 'PDF')}>
              <DropdownMenuItem>Save as .PDF</DropdownMenuItem>
            </a>
            <a href={downloadUrl(cvContextsIds, 'DOCX')}>
              <DropdownMenuItem>Save as .DOCX</DropdownMenuItem>
            </a>
            <a href={downloadUrl(cvContextsIds, 'JSON')}>
              <DropdownMenuItem>Save as .JSON</DropdownMenuItem>
            </a>
          </DropdownMenuContent>
        </DropdownMenu>
      );
    },
  }),
];

type Props = {
  search: string;
  collections: number[];
  filtersId?: number;
};
const DashboardTableLayout: FC<Props> = ({
  search,
  collections,
  filtersId,
}) => {
  const queryClient = useQueryClient();

  const { mutate } = useOnboarding();
  const {
    onboardingState: [{ run, steps }, setState],
    fixtures,
  } = useDashboardOnboarding();
  const { selectedContexts, setSelectedContexts } = useDashboard();

  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [editModeActive, setEditModeActive] = useState(false);
  const { mutate: patchExternalCandidate } = usePatchExternalCandidate();

  const rowSelection = Object.fromEntries(
    selectedContexts.map(({ id }) => [id, true])
  );
  const params = useDashboard();

  const { mutate: retry } = useMutation({
    mutationFn: (id: number) => postRetryExternalCandidate(id),
    mutationKey: ['externalCandidateRetry'],
    onSuccess: () => {
      queryClient.invalidateQueries(queries.externalCandidates.list._def);
    },
  });
  const { data: apiData, isPreviousData } = useQuery({
    ...queries.externalCandidates.list({
      pagination: params.pagination,
      search,
      collections,
      salary_order: params.sortingState,
      target_currency: params.targetCurrency,
      target_period: params.targetPeriod,
      filtersId,
    }),
    refetchInterval: (queryData) =>
      queryData?.results.some(({ status }) => status === 'PROCESSING')
        ? 1000 * 5
        : false,
    keepPreviousData: true,
    staleTime: 5 * 60 * 1000,
  });

  const data =
    run && (apiData?.count ?? 0) <= 0
      ? DjangoDataZodSchema(externalCandidateListSchema).parse(fixtures)
      : apiData;

  const table = useReactTable({
    defaultColumn: {
      enableSorting: false,
    },
    enableColumnResizing: false,
    meta: {
      onProfilePrefetch: (id) =>
        queryClient.prefetchQuery(queries.externalCandidates.detail(id)),

      editModeActive,
      setEditModeActive,
      updateData: (rowId, columnId, value) => {
        patchExternalCandidate({
          id: Number(rowId),
          data: { [columnId]: value },
        });
      },
      params,
    },
    enableSortingRemoval: true,
    manualSorting: true,
    getRowId: ({ id }) => id.toString(),
    columns: useMemo(() => columns(retry), [retry]),
    data: useMemo(() => data?.results ?? [], [JSON.stringify(data)]),
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnPinning: { left: ['select'], right: ['settings'] },
      columnVisibility,
      columnOrder,
      rowSelection,
      sorting: params.sortingState,
    },
    onColumnOrderChange: (updaterOrValue) => {
      setColumnOrder(updaterOrValue);
      saveColumnOrder(
        columnOrder,
        updaterOrValue,
        LocalStorage.DASHBOARD_LIST_ORDER
      );
    },
    onColumnVisibilityChange: (updaterOrValue) => {
      setColumnVisibility(updaterOrValue);
      saveVisibility(
        columnVisibility,
        updaterOrValue,
        LocalStorage.DASHBOARD_LIST_VISIBILITY
      );
    },
    onSortingChange: (updater) => {
      const setNewSortingParam = (state: SortingState) =>
        params.setParams({
          salaryOrder: state.map(
            ({ desc, id }) => `${desc ? '-' : ''}${id}`
          )[0],
        });

      if (typeof updater === 'function') {
        const newSort = updater(params.sortingState);
        return setNewSortingParam(newSort);
      }
      setNewSortingParam(updater);
    },

    enableRowSelection: true,
    onRowSelectionChange: (updater) => {
      const getContextObjectFromSelectedRows = (ids: {
        [key: string]: boolean;
      }) => {
        const newIds = Object.keys(ids)
          .filter((oldId) => !Object.keys(rowSelection).includes(oldId))
          .map((id) => Number(id));

        const newSelectedContexts =
          data?.results.filter(({ id: candidateId }) =>
            newIds.includes(candidateId)
          ) ?? [];
        return [
          ...newSelectedContexts,
          ...selectedContexts.filter(({ id }) =>
            Object.keys(ids).includes(id.toString())
          ),
        ];
      };

      if (typeof updater === 'function') {
        return setSelectedContexts(
          getContextObjectFromSelectedRows(updater(rowSelection))
        );
      }
      setSelectedContexts(getContextObjectFromSelectedRows(updater));
    },
  });

  useEffect(() => {
    setColumnVisibility(
      getSavedVisibility(LocalStorage.DASHBOARD_LIST_VISIBILITY)
    );
    setColumnOrder(getSavedColumnOrder(LocalStorage.DASHBOARD_LIST_ORDER));
  }, []);

  return (
    <>
      <Joyride
        disableScrollParentFix
        onFinished={() => {
          setState((prev) => ({ ...prev, run: false }));
          mutate('DASHBOARD');
        }}
        steps={steps}
        run={run}
      />

      <SelectModeControls
        onClear={() => setSelectedContexts([])}
        allRowsSelcted={table.getIsAllRowsSelected()}
        toggleAllRowsSelected={table.toggleAllRowsSelected}
        selectedContexts={selectedContexts}
      />
      <motion.div className="mt-2 overflow-auto" layout="position">
        <Table
          showSkeleton={isPreviousData}
          size="auto"
          hideVisibilityAndOrdering
          tableHeaderClassName={() => 'whitespace-nowrap'}
          headerPrefix={(header) =>
            header.id === 'converted_salary' ? (
              <SalaryColumnConfigurationDialog>
                <Icon icon="teenyicons:adjust-horizontal-alt-solid" />
              </SalaryColumnConfigurationDialog>
            ) : null
          }
          table={table}
        />
      </motion.div>
    </>
  );
};

export default DashboardTableLayout;
type EditableCellProps = {
  cellProps: CellContext<ExternalCandidateList, unknown>;
  type?: 'text' | 'number';
};
const EditableCell: FC<EditableCellProps> = ({
  cellProps: {
    getValue,
    table,
    row: { id: rowId },
    column: { id },
  },
  type = 'text',
}) => {
  const initialValue = getValue();
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState<string | null>(null);

  const onBlur = () => {
    if (!error) table.options.meta?.updateData(rowId, id, value);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const re = /^[0-9\b +()-]+$/;
    if (
      type === 'number' &&
      e.target.value !== '' &&
      !re.test(e.target.value) &&
      e.target.value.length > (value as string).length
    )
      return;

    setValue(e.target.value);
    if (id === 'email')
      await string()
        .email()
        .validate(e.target.value)
        .then(() => setError(null))
        .catch((reason) => setError(reason.message));
  };
  return (
    <div className="cursor-text rounded-lg bg-neutral-900 px-2">
      <input
        size={(value as string).length}
        className={classNames(
          'border-none bg-transparent w-full outline-none',
          {
            'text-error': error,
          }
        )}
        value={value as string}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  );
};
