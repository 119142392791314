import { z } from 'zod';

import authService from './auth/auth';
import { apiRoutes } from './config';

const offerSearchSchema = z.object({
  id: z.number(),
  title: z.string(),
  text: z.string(),
  requirements: z.array(
    z.object({
      id: z.number(),
      text: z.string(),
      weight: z.number(),
    })
  ),
  aiParsedFilters: z.object({
    availability: z.array(z.string()).optional(),
    city: z.array(z.string()).optional(),
    country: z.array(z.string()).optional(),
    jobStart: z.array(z.string()).optional(),
    languages: z.array(z.string()).optional(),
    minExp: z.number().optional(),
    relocation: z.boolean().nullable().optional(),
    seniority: z.array(z.string()).optional(),
    skills: z.array(z.string()).optional(),
  }),
  keywords: z.object({
    keywords: z.array(z.string()),
  }),
  offerSummary: z.array(z.string()),
  characteristics: z.array(z.string()),
  processingState: z.object({
    summaryCompleted: z.boolean(),
    filtersCompleted: z.boolean(),
    keywordsCompleted: z.boolean(),
    requirementsCompleted: z.boolean(),
    characteristicsCompleted: z.boolean(),
  }),
});

type ApiOfferSearch = z.infer<typeof offerSearchSchema>;

export const transformApiOfferSearch = (offerSearch: ApiOfferSearch) => {
  return {
    ...offerSearch,
    aiParsedFilters: {
      ...offerSearch.aiParsedFilters,
      languages: offerSearch.aiParsedFilters.languages?.map((language) =>
        language.split(',').map((v) => Number(v))
      ) as [number, number][],
      country: offerSearch.aiParsedFilters.country?.[0]
        ? Number(offerSearch.aiParsedFilters.country?.[0])
        : null,
      city: offerSearch.aiParsedFilters.city?.[0]
        ? Number(offerSearch.aiParsedFilters.city?.[0])
        : null,
      relocation:
        // eslint-disable-next-line no-nested-ternary
        offerSearch.aiParsedFilters.relocation === true
          ? ['Yes']
          : offerSearch.aiParsedFilters.relocation === false
          ? ['No']
          : [],
    },
  };
};

const transformOfferSearch = (offerSearch: Partial<OfferSearch>) => {
  const relocation = offerSearch.aiParsedFilters?.relocation;
  return {
    ...offerSearch,
    aiParsedFilters: {
      ...offerSearch.aiParsedFilters,
      languages: offerSearch.aiParsedFilters?.languages?.map((language) =>
        language.join(',')
      ),
      country: offerSearch.aiParsedFilters?.country
        ? [offerSearch.aiParsedFilters?.country.toString()]
        : [],
      city: offerSearch.aiParsedFilters?.city
        ? [offerSearch.aiParsedFilters?.city.toString()]
        : [],
      relocation:
        relocation?.length === 0 || relocation?.length === 2
          ? null
          : !!relocation?.includes('Yes'),
    },
  };
};
export type OfferSearch = ReturnType<typeof transformApiOfferSearch>;

export const postReasoningSearch = async (searchText: string) => {
  const { data } = await authService.post(
    apiRoutes.search.reasoningSearch._root,
    undefined,
    {
      params: {
        search_text: searchText,
      },
    }
  );
  const parsedData = offerSearchSchema.parse(data);
  return transformApiOfferSearch(parsedData);
};

export const getReasoningSearch = async (id: number) => {
  const { data } = await authService.get(
    apiRoutes.search.reasoningSearch.detail(id)
  );
  const parsedData = offerSearchSchema.parse(data);
  return transformApiOfferSearch(parsedData);
};

export const patchReasoningSearch = async ({
  formData,
  id,
}: {
  id: number;
  formData: Partial<OfferSearch>;
}) => {
  const { data } = await authService.patch(
    apiRoutes.search.reasoningSearch.detail(id),
    transformOfferSearch(formData)
  );
  return offerSearchSchema.parse(data);
};
