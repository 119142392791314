import { createQueryKeys } from '@lukemorales/query-key-factory';

import {
  getBulletPoints,
  getDetailBulletPoints,
} from '@/services/bullet-points';

export const bulletPoints = createQueryKeys('bulletPoints', {
  list: ({ companies }: { companies: number }) => ({
    queryKey: [companies],
    queryFn: ({ pageParam }) =>
      getBulletPoints({
        offset:
          // eslint-disable-next-line no-unsafe-optional-chaining
          pageParam * 20,
        pageSize: 20,
        companies,
      }),
  }),
  detail: (id) => ({
    queryKey: [id],
    queryFn: () => getDetailBulletPoints(id),
  }),
});
