import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { type FC } from 'react';

import { useProfile } from '@/hooks/useProfile';
import { useUserCurrentSubscription } from '@/hooks/useUserCurrentSubscription';
import { EnumAccountType } from '@/services/auth/auth';

import type { ButtonVariantProps } from './Button';
import Button from './Button';

type Props = {
  isUnlimitedSubscription?: boolean;
  className?: string;
} & ButtonVariantProps;
const SubscribeButton: FC<Props> = ({
  isUnlimitedSubscription,
  size,
  variant,
  className,
}) => {
  const { pathname } = useRouter();
  const { data: profileData } = useProfile();
  const { data: userCurrentSubscriptionData } = useUserCurrentSubscription();

  const { label: buttonLabel, href: buttonHref } = (() => {
    if (isUnlimitedSubscription || userCurrentSubscriptionData)
      return { label: 'Manage', href: '/dashboard/pricing/customer-portal' };

    return { label: 'Subscribe', href: '/dashboard/pricing' };
  })();

  const displayButton =
    !(pathname.includes('pricing') && buttonLabel === 'Subscribe') &&
    (profileData?.accountType === 'ADMIN' ||
      profileData?.accountType === EnumAccountType.CUSTOMER);

  return (
    <Link
      href={buttonHref}
      className={classNames('flex', className, {
        hidden: !displayButton,
      })}
    >
      <Button tabIndex={-1} size={size} variant={variant} className="w-full">
        {buttonLabel}
      </Button>
    </Link>
  );
};

export default SubscribeButton;
