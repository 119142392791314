/* eslint-disable jsx-a11y/control-has-associated-label */
import type { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import {
  faGripVertical,
  faPlus,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import type { SelectOptions } from '@mindpal-co/mindpal-ui';
import { cva, type VariantProps } from 'class-variance-authority';
import classNames from 'classnames';
import type { ComponentProps, FC } from 'react';
import type { FieldError, Merge } from 'react-hook-form';

import MailmanWithEnvelope from '@/assets/drawing-illustrations/MailmanWithEnvelope.svg';
import Button from '@/components/Button';
import ErrorAccordion from '@/components/ErrorAccordion';
import type { ControlStyleProps } from '@/components/Select';
import { Select } from '@/components/Select';
import Text from '@/components/Text';

import type { ErrorDetailsProps } from '../ArrayDataNestedForm';
import Label from './Label';
import TextInputField from './TextInputField';

type EmptyLabelStyles = VariantProps<ReturnType<typeof emptyLabelStyles>>;
const emptyLabelStyles = ({
  error,
  disabled,
}: {
  error?: ErrorType;
  disabled?: boolean;
}) =>
  cva('', {
    variants: {
      emptyLabelVariant: {
        'neutral-1000': classNames(
          'rounded-lg border-2 border-transparent bg-neutral-900 p-4',
          {
            '!bg-neutral-900 !border !border-neutral-700': disabled && !error,
            '!bg-error-20% !border-error': error?.message,
          }
        ),
        lightGray: classNames(
          'rounded-lg border-2 border-transparent bg-jb-neutral-200 p-4 [&>p]:text-jb-neutral-600',
          {
            '!bg-neutral-900 !border !border-neutral-700': disabled && !error,
            '!bg-error-20% !border-error': error?.message,
          }
        ),
      },
    },
    defaultVariants: {
      emptyLabelVariant: 'neutral-1000',
    },
  });

type ErrorType = Merge<
  FieldError,
  (
    | Merge<FieldError, [(FieldError | undefined)?, (FieldError | undefined)?]>
    | undefined
  )[]
>;

type Props<T, K> = EmptyLabelStyles & {
  options: SelectOptions<T>;
  mainLabel?: string;
  required?: boolean;
  buttonLabel?: string;
  addButtonClassName?: string;
  labelClassName?: string;
  containerClassName?: string;
  deleteButtonIcon?: IconDefinition;
  deleteButtonClassName?: string;
  labels?: [string, string];
  placeholders?: [string, string];
  emptyLabel?: string;
  name?: string;
  onCreateOption?: (inputValue: string) => void;
  isCreatable?: boolean[];
  isDraggable?: boolean;
  disabled?: boolean;
  addButtonsVariant?: ComponentProps<typeof Button>['variant'];
  deleteButtonsVariant?: ComponentProps<typeof Button>['variant'];
  selectVariant?: ComponentProps<typeof Select>['variant'];
  selectSize?: ComponentProps<typeof Select>['sizeVariant'];
  textInputVariant?: ComponentProps<typeof TextInputField>['variant'];
  textInputSize?: ComponentProps<typeof TextInputField>['sizeVariant'];
} & ErrorDetailsProps<ErrorType> &
  (
    | {
        type: 'select';
        secondOptions: SelectOptions<K>;
        value: [T, K][];
        onChange: (value: [T, K][]) => void;
      }
    | {
        type: 'number';
        value: [T, number | null][];
        onChange: (value: [T, number | null][]) => void;
      }
    | {
        type: 'text';
        value: [T, string | null][];
        onChange: (value: [T, string | null][]) => void;
        textInputProps?: ComponentProps<typeof TextInputField>;
      }
  ) &
  ControlStyleProps;

export const defaultValue: [null, null] = [null, null];

const SelectValuePair = <
  T extends number | string | null,
  K extends number | string | null
>({
  value,
  options,
  mainLabel,
  emptyLabelVariant,
  required,
  buttonLabel = 'Add',
  addButtonClassName,
  labelClassName,
  containerClassName,
  deleteButtonIcon = faXmark,
  deleteButtonClassName,
  labels = ['', ''],
  placeholders = ['Type to search', 'Type to search'],
  error,
  canHaveErrorMessage = true,
  emptyLabel = mainLabel,
  name,
  isDraggable,
  onCreateOption,
  isCreatable = [false, false],
  disabled,
  addButtonsVariant = 'secondary',
  deleteButtonsVariant = 'secondary',
  selectVariant = 'neutral-1000',
  selectSize = 'base',
  textInputVariant = 'neutral-1000',
  textInputSize = 'base',
  ...props
}: Props<T, K>) => {
  const handleChange = (
    index: number,
    newValue?: T,
    level?: K | number | string | null
  ) => {
    const res = [...value];
    res[index] = [newValue ?? res[index][0], level ?? res[index][1]] as
      | [T, K]
      | [T, number];
    props.onChange(
      res as [T, K][] & [T, number | null][] & [T, string | null][]
    );
  };

  const getOptionById = <X,>(
    id: X,
    selectOptions: SelectOptions<X>,
    index: number
  ) => {
    if (id === null) return null;
    const foundOption = selectOptions.find((option) => option.value === id);
    if (foundOption) return foundOption;
    if (isCreatable[index])
      return {
        label: id as string,
        value: id as X,
      };
    return undefined;
  };

  const removeValue = (index: number) => {
    const filteredArr = [...value];
    filteredArr.splice(index, 1);
    props.onChange(
      filteredArr as [T, K][] & [T, number | null][] & [T, string | null][]
    );
  };

  return (
    <div className="mt-3 flex flex-col gap-3">
      {mainLabel && <Label required={required}>{mainLabel}</Label>}
      <div className={classNames('flex flex-col gap-3', containerClassName)}>
        {value.length <= 0 && (
          <EmptyLabel
            emptyLabelVariant={emptyLabelVariant}
            emptyLabel={emptyLabel}
            error={error}
          />
        )}
        {value.length > 0 && labels.length === 2 && (
          <div className="flex gap-3">
            <Text
              variant="body-caption"
              className={classNames('flex-1 text-neutral-300', labelClassName)}
            >
              {labels[0]}
            </Text>
            <Text
              variant="body-caption"
              className={classNames('flex-1 text-neutral-300', labelClassName)}
            >
              {labels[1]}
            </Text>
            <div className="w-8" />
          </div>
        )}
        <DragDropContext
          onDragEnd={({ source, destination }) => {
            if (!destination) return;
            const reorderedArr = [...value];
            const [movedItem] = reorderedArr.splice(source.index, 1);
            reorderedArr.splice(destination.index, 0, movedItem);
            props.onChange(
              reorderedArr as [T, K][] &
                [T, number | null][] &
                [T, string | null][]
            );
          }}
        >
          <Droppable droppableId="drop">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="flex flex-col"
              >
                {value.map((option, index) => (
                  <Draggable
                    key={option[0] + index.toString()}
                    index={index}
                    draggableId={JSON.stringify(option)}
                    isDragDisabled={!isDraggable || disabled}
                  >
                    {(draggableProvided) => (
                      <div
                        {...draggableProvided.draggableProps}
                        ref={draggableProvided.innerRef}
                        className="mb-3"
                      >
                        <div className="flex items-center gap-3">
                          {isDraggable && (
                            <div
                              {...draggableProvided.dragHandleProps}
                              className="self-center"
                            >
                              <FontAwesomeIcon icon={faGripVertical} />
                            </div>
                          )}
                          <Select
                            variant={selectVariant}
                            isDisabled={disabled}
                            fullWidth
                            placeholder={placeholders[0]}
                            onChange={(newValue) =>
                              handleChange(index, newValue?.value, undefined)
                            }
                            onCreateOption={onCreateOption}
                            value={getOptionById(option[0], options, 0)}
                            canHaveErrorMessage={false}
                            options={options}
                            sizeVariant={selectSize}
                            error={
                              error?.[index]?.[0]?.message?.toString() ??
                              error?.[index]?.message ??
                              error?.message
                            }
                            name={name}
                            isCreatable
                            classNames={{
                              placeholder: () => 'whitespace-nowrap truncate',
                            }}
                          />
                          {props.type === 'select' ? (
                            <Select
                              variant={selectVariant}
                              fullWidth
                              placeholder={placeholders[1]}
                              onChange={(newValue) =>
                                handleChange(index, undefined, newValue?.value)
                              }
                              value={getOptionById(
                                option[1],
                                props.secondOptions,
                                1
                              )}
                              canHaveErrorMessage={false}
                              options={props.secondOptions}
                              sizeVariant={selectSize}
                              error={
                                error?.[index]?.[1]?.message?.toString() ??
                                error?.[index]?.message ??
                                error?.message
                              }
                              classNames={{
                                placeholder: () => 'whitespace-nowrap truncate',
                              }}
                            />
                          ) : (
                            props.type === 'number' && (
                              <TextInputField
                                variant={textInputVariant}
                                disabled={disabled}
                                placeholder={placeholders[1]}
                                value={
                                  option[1] !== null && !Number.isNaN(option[1])
                                    ? option[1]
                                    : ''
                                }
                                step={0.1}
                                type="number"
                                onChange={(e) =>
                                  handleChange(
                                    index,
                                    undefined,
                                    e.target.valueAsNumber
                                  )
                                }
                                canHaveErrorMessage={false}
                                sizeVariant={textInputSize}
                                error={
                                  error?.[index]?.[1]?.message?.toString() ??
                                  error?.[index]?.message ??
                                  error?.message
                                }
                              />
                            )
                          )}
                          {props.type === 'text' && (
                            <TextInputField
                              variant={textInputVariant}
                              disabled={disabled}
                              placeholder={placeholders[1]}
                              value={option[1] ?? ''}
                              type="text"
                              onChange={(e) =>
                                handleChange(index, undefined, e.target.value)
                              }
                              canHaveErrorMessage={false}
                              sizeVariant={textInputSize}
                              error={
                                error?.[index]?.[1]?.message?.toString() ??
                                error?.[index]?.message ??
                                error?.message
                              }
                              {...props.textInputProps}
                            />
                          )}
                          <Button
                            disabled={disabled}
                            variant={deleteButtonsVariant}
                            size="sm"
                            prefixIcon={deleteButtonIcon}
                            className={classNames(
                              {
                                'before:!w-[170%]':
                                  deleteButtonsVariant === 'secondary',
                              },
                              deleteButtonClassName
                            )}
                            onClick={() => removeValue(index)}
                          />
                        </div>
                        {canHaveErrorMessage && (
                          <ErrorAccordion
                            error={
                              error?.[index]?.[0]?.message?.toString() ??
                              error?.[index]?.[1]?.message?.toString() ??
                              error?.[index]?.message
                            }
                          />
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <Button
        disabled={disabled}
        name={name}
        className={classNames('self-center', addButtonClassName)}
        prefixIcon={faPlus}
        size="sm"
        variant={addButtonsVariant}
        onClick={() =>
          props.onChange([...value, defaultValue] as [T, K][] &
            [T, number | null][] &
            [T, string | null][])
        }
      >
        {buttonLabel}
      </Button>
      {canHaveErrorMessage && <ErrorAccordion error={error?.message} />}
    </div>
  );
};

export default SelectValuePair;

type EmptyLabelProps = {
  error?: ErrorType;
  emptyLabel?: string;
} & EmptyLabelStyles;
const EmptyLabel: FC<EmptyLabelProps> = ({
  emptyLabelVariant,
  error,
  emptyLabel = 'selectors',
}) => {
  if (emptyLabelVariant === 'lightGray') {
    return (
      <div
        className={classNames(
          emptyLabelStyles({ error })({ emptyLabelVariant }),
          'flex flex-col items-center gap-3'
        )}
      >
        <div className="w-40">
          <MailmanWithEnvelope />
        </div>
        <Text className="text-center text-neutral-300">{`No ${emptyLabel?.toLowerCase()} selected`}</Text>
      </div>
    );
  }
  return (
    <div className={emptyLabelStyles({ error })({ emptyLabelVariant })}>
      <Text className="text-center text-neutral-300">{`No ${emptyLabel?.toLowerCase()} selected`}</Text>
    </div>
  );
};
