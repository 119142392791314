import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

type SkillsState = {
  hardSkills: string[];
  addNewHardSkill: (hardSkill: string) => void;
  softSkills: string[];
  addNewSoftSkills: (softSkill: string) => void;
};

export const useSkillsLocalStorage = create<SkillsState>()(
  persist(
    (set, get) => ({
      hardSkills: get()?.hardSkills ?? [],
      addNewHardSkill: (newHardSkills) =>
        set({
          hardSkills: [...get().hardSkills, newHardSkills],
        }),
      softSkills: get()?.softSkills ?? [],
      addNewSoftSkills: (newSoftSkills) =>
        set({
          softSkills: [...get().softSkills, newSoftSkills],
        }),
    }),
    {
      name: 'skills-store',
      storage: createJSONStorage(() => localStorage),
    }
  )
);
