import { useQuery } from '@tanstack/react-query';

import type { UseQueryFactoryProps } from '@/queries';
import { queries } from '@/queries';

export const useSkills = (
  props?: UseQueryFactoryProps<typeof queries.common.skills>
) => {
  return useQuery({
    ...queries.common.skills,
    staleTime: 1000 * 60 * 5,
    ...props,
  });
};
