import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { EnumAccountType } from '@/services/auth/auth';

import { useProfile } from './useProfile';

export const useRedirectAuthUsers = () => {
  const { pathname, replace } = useRouter();
  const { data } = useProfile();

  const isCustomer = data && data?.accountType === EnumAccountType.CUSTOMER;
  const isCandidate = data && data?.accountType === EnumAccountType.CANDIDATE;
  useEffect(() => {
    if (
      (!pathname.includes('/dashboard') ||
        pathname.includes('/dashboard/auth')) &&
      isCustomer
    ) {
      replace('/dashboard');
    }
    if (pathname.includes('/dashboard') && isCandidate) {
      replace('/jobs');
    }
  }, [data]);
};
