export const getStepperStatus = ({
  index,
  currentIndex,
  offset = 0,
}: {
  index: number;
  currentIndex: number;
  offset?: number;
}) => {
  if (index === currentIndex + offset) return 'inprogress';
  if (index < currentIndex + offset) return 'completed';
  return 'notstarted';
};
