import { useQuery } from '@tanstack/react-query';

import type { UseQueryFactoryProps } from '@/queries';
import { queries } from '@/queries';

export const useLanguages = (
  props?: UseQueryFactoryProps<typeof queries.common.languages>
) => {
  return useQuery({
    ...queries.common.languages,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
    ...props,
  });
};
